/* eslint-disable @typescript-eslint/no-use-before-define */
import Image, { FluidObject } from 'gatsby-image';
import React, { FC } from 'react';
import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import Appear, { AppearProps } from './Appear';
import Title from './atoms/Title';

const Sellpoint: FC<{ title: string; image?: FluidObject | null } & AppearProps> = ({ title, image, ...props }) => {
    return (
        <AppearContainer {...props}>
            <Wrapper>
                <Title variant="small">{title}</Title>

                {image && (
                    <ImgWrapper>
                        <Image fluid={image} />
                    </ImgWrapper>
                )}
            </Wrapper>
        </AppearContainer>
    );
};

export default Sellpoint;

const ImgWrapper = styled.div`
    position: relative;
    width: 60%;
    > div {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
`;

const commonStyles = css`
    flex-shrink: 0;
    scroll-snap-align: start;
`;

const AppearContainer = styled(Appear)`
    ${commonStyles}
`;

const Wrapper = animated(styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    text-align: center;
    img {
        object-fit: contain !important;
    }
    box-shadow: 0 1.6rem 4rem 0 rgba(59, 118, 160, 0.08);
    /* height: 15rem; */
    background: white;
    transition: transform 250ms ease-in-out;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        :hover {
            transform: scale(1.2);
        }
    }
`);
