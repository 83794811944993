/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC } from 'react';
import styled from 'styled-components';
import video from '../../static/video/bgvideo.mp4';
import Appear from '../components/Appear';
import ActionDraw from '../components/atoms/ActionDraw';
import Body from '../components/atoms/Body';
import BookingDraw from '../components/atoms/BookingDraw';
import Box from '../components/atoms/Box';
import Button from '../components/atoms/Button';
import ContentWrapper from '../components/atoms/ContentWrapper';
import Flex from '../components/atoms/Flex';
import MoneyDraw from '../components/atoms/MoneyDraw';
import Stack from '../components/atoms/Stack';
import Title from '../components/atoms/Title';
import Content from '../components/Content';
import Layout from '../components/Layout';
import List, { Item } from '../components/List';
import Sellpoint from '../components/Sellpoint';
import { useContactFormDispatch } from '../context/contactform';
import { useStepFormDispatch } from '../context/stepform';
import SEO from '../components/Seo';
import StepInline from '../components/StepInline';
import Terms from '../components/atoms/Terms';
import { graphql, useStaticQuery } from 'gatsby';
import { IndexQuery } from '../graphql';
import { FluidObject } from 'gatsby-image';

export const query = graphql`
    query Index {
        sellPoint1: file(relativePath: { eq: "Sellpoint1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        sellPoint2: file(relativePath: { eq: "Sellpoint2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        sellPoint3: file(relativePath: { eq: "Sellpoint3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        sellPoint4: file(relativePath: { eq: "Sellpoint4.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const Index: FC = () => {
    const { onOpen: onStepFormOpen } = useStepFormDispatch();
    const { onOpen: onContactFormOpen } = useContactFormDispatch();
    const { sellPoint1, sellPoint2, sellPoint3, sellPoint4 } = useStaticQuery<IndexQuery>(query);

    const renderCta = (className: string) => (
        <Cta className={className} onClick={onContactFormOpen} id="contact-cta">
            <Box>
                <Title variant="large">Waarom via ons verkopen?</Title>
                <List style={{ marginTop: '1.6rem' }}>
                    <Item>Ontvang van ons een goed bod op uw pand</Item>
                    <Item>Uw pand wordt via ons snel en discreet verkocht</Item>
                    <Item>Door uw pand via ons te verkopen betaalt u geen makelaars kosten</Item>
                    <Item>Het is voor ons mogelijk om uw pand zonder voorbehoud van financiering af te nemen</Item>
                    <Item>Het ontruimen van de woning nemen wij voor onze rekening</Item>
                </List>
                <Button onClick={onContactFormOpen}>Contact opnemen</Button>
            </Box>
        </Cta>
    );

    return (
        <>
            <SEO
                title="Binnen 24 uur een bod"
                description="Wij bieden u de mogelijkheid waarbij uw pand op een snelle, eerlijke en discrete
                                        manier wordt verkocht. Binnen 24 uur ontvangt u van ons een vrijblijvend bod op
                                        uw pand. Zonder stress en onzekerheid."
            />
            <Layout>
                <VideoWrapper from={{ opacity: 1 }} to={{ opacity: 1 }}>
                    <StyledVideo autoPlay loop muted>
                        <source src={video} type="video/mp4" />
                    </StyledVideo>
                    <VideoOverlay />
                </VideoWrapper>
                <MainSection id="main">
                    <StyledContentWrapper>
                        <Flex height="100%" pb={0} style={{ position: 'relative' }}>
                            <Box m="auto" pr={[null, null, null, '30rem', null, '24rem']}>
                                <Stack spacing={4} style={{ textAlign: 'center' }}>
                                    <Title style={{ color: 'white', maxWidth: '48rem' }} variant="large">
                                        Ontvang van ons een vrijblijvend bod op uw pand
                                    </Title>
                                    <Body style={{ maxWidth: '48rem' }} variant="huge">
                                        Wij bieden u de mogelijkheid waarbij uw pand op een snelle, eerlijke en discrete
                                        manier wordt verkocht. Binnen 24 uur ontvangt u van ons een vrijblijvend bod op
                                        uw pand. Zonder stress en onzekerheid.
                                    </Body>
                                </Stack>
                            </Box>
                            <StepInline className="gt-m" />
                        </Flex>
                        {/* <ScrollIcon
                        delay={1000}
                        from={{ opacity: 0 }}
                        to={{ opacity: 1 }}
                        onClick={() =>
                            typeof document !== undefined &&
                            document.getElementById('contact-cta')?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                            })
                        }
                    /> */}
                    </StyledContentWrapper>
                </MainSection>
                <StepInline className="lt-m" />

                {/* <ContentWrapper>
                <Feature />
            </ContentWrapper> */}
                <SellpointSection>
                    <ContentWrapper display="flex" flexDirection="column" alignItems="center">
                        <Title mt={['7.2rem', null, 0, null, '7.2rem']} variant="large">
                            Verkoop uw pand via ons
                        </Title>
                        <Body>Wij zijn goed in het bemiddelen van meerdere soorten panden</Body>
                        <Flex mt={7} width={[1]} justifyContent="center">
                            <SellpointGrid flexWrap="wrap">
                                <Sellpoint title="Woningen" image={sellPoint2?.childImageSharp?.fluid as FluidObject} />
                                <Sellpoint
                                    title="Bedrijfspanden"
                                    delay={100}
                                    image={sellPoint1?.childImageSharp?.fluid as FluidObject}
                                />
                                <Sellpoint
                                    title="Verhuurd"
                                    delay={200}
                                    image={sellPoint3?.childImageSharp?.fluid as FluidObject}
                                />
                                <Sellpoint
                                    title="Achterstallig onderhoud"
                                    delay={300}
                                    image={sellPoint4?.childImageSharp?.fluid as FluidObject}
                                />
                            </SellpointGrid>
                        </Flex>
                    </ContentWrapper>
                </SellpointSection>
                {renderCta('lt-l')}
                <section id="hoe-werkt-het" style={{ position: 'relative' }}>
                    {renderCta('gt-l')}

                    <ContentWrapper mt={[7, null, 0]}>
                        <Appear>
                            <Title elementType="h2" variant="huge">
                                Hoe werkt het?
                            </Title>
                        </Appear>

                        <Stack spacing={['7.2rem', null, '10rem']}>
                            <Content
                                mt={[5, null, '7.2rem']}
                                right={
                                    <Box className="text" pl={[null, null, '5.2rem']}>
                                        <Title elementType="h2" variant="large">
                                            Stap 1: Vul uw gegevens in via het formulier
                                        </Title>
                                        <Body variant="huge">
                                            Wij zullen uw aanvraag in behandeling nement. U bent verzekerd van een
                                            eerlijk en marktconform bod. De aanvraag is uiteraard kosteloos en
                                            vrijblijvend.
                                        </Body>
                                    </Box>
                                }
                                leftWidth={[1, null, 0.4]}
                                left={<BookingDraw width="100%" />}
                                flexDirection={['column', null, 'row']}
                            />
                            <Content
                                leftWidth={[1, null, 0.4]}
                                flexDirection={['column', null, 'row-reverse']}
                                left={<ActionDraw width="100%" />}
                                right={
                                    <Box className="text" pr={[null, null, '5.2rem']}>
                                        <Title elementType="h2" variant="large">
                                            Stap 2: Wij gaan voor je aan de slag
                                        </Title>
                                        <Body variant="huge">
                                            Zodra u het formulier heeft ingevuld zullen wij voor u aan de slag gaan en
                                            kijken wat wij voor u kunnen betekenen. Het kan zo zijn dat wij de woning
                                            een keer moeten bezichtigen voordat wij een bod kunnen uitbrengen.
                                        </Body>
                                    </Box>
                                }
                            />
                            <Content
                                leftWidth={[1, null, 0.4]}
                                flexDirection={['column', null, 'row']}
                                left={<Terms width="100%" />}
                                right={
                                    <Box className="text" pl={[null, null, '5.2rem']}>
                                        <Title elementType="h2" variant="large">
                                            Stap 3: Akkoord
                                        </Title>
                                        <Body variant="huge">
                                            Mocht u akkoord gaan met het bod zullen wij een opleverdatum inplannen en
                                            een afspraak inplannen bij de notaris.
                                        </Body>
                                    </Box>
                                }
                            />
                            <Content
                                leftWidth={[1, null, 0.4]}
                                flexDirection={['column', null, 'row-reverse']}
                                left={<MoneyDraw width="100%" />}
                                right={
                                    <Box className="text" pr={[null, null, '5.2rem']}>
                                        <Title elementType="h2" variant="large">
                                            Stap 4: Ontvang uw geld en geniet van uw keuze!
                                        </Title>
                                        <Body variant="huge">
                                            Door uw woning via ons te verkopen heeft u ontzettend veel tijd, kosten en
                                            onzekerheid bespaard.
                                        </Body>
                                    </Box>
                                }
                            />
                            <SecondCta onClick={() => onStepFormOpen()}>
                                <div>
                                    <Title elementType="h2" variant="large">
                                        Vrijblijvend bod?
                                    </Title>
                                    <Body variant="huge">
                                        Vul in 3 stappen het formulier in en krijg een vrijblijvend bod!
                                    </Body>
                                    <Button variant="transparant" onClick={() => onStepFormOpen()}>
                                        Doorgaan
                                    </Button>
                                </div>
                            </SecondCta>
                        </Stack>
                    </ContentWrapper>
                </section>
                <ContactSection>
                    <Box px={5}>
                        <Title elementType="h2" variant="large">
                            Gelijk contact opnemen?
                        </Title>
                        <Body variant="huge">Heeft u vragen? Wij zijn hier om uw vragen te beantwoorden.</Body>
                        <Button onClick={onContactFormOpen}>Contact opnemen</Button>
                    </Box>
                </ContactSection>
            </Layout>
        </>
    );
};

export default Index;

const SellpointGrid = styled(Flex)`
    display: grid;

    grid-template-columns: 1fr;
    grid-gap: 1.6rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 3.2rem;
    }

    max-width: 70rem;
    > div {
        grid-column: span 1;
    }
`;

// const Feature = styled.div`
//     width: 100%;
//     height: 12rem;
//     background: white;
//     box-shadow: 0 1.6rem 2.4rem 0 rgba(59, 118, 160, 0.03), 0 2.4rem 8rem 0 rgba(59, 118, 160, 0.05);
// `;
const SellpointSection = styled.section`
    background: ${({ theme }) => theme.colors.neutral[6]};
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding-bottom: 7.2rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        padding-bottom: 21rem;
    }
    padding-bottom: 3.2rem;
    position: relative;
`;
const ContactSection = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    color: white;
    background: #db6588;
    padding: 2.4rem 0;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding: 3.2rem 0;

        padding: 7.2rem 0;
        > div {
            max-width: 32rem;
        }
    }

    button {
        margin-top: 3.2rem;
    }
`;

const Cta = styled(Appear)`
    > div {
        max-width: 40rem;

        > button {
            align-self: flex-start;
            flex-shrink: 0;
            margin-top: 2.4rem;
        }
    }

    align-items: center;

    background: #db6588;
    padding: 3.2rem 1.6rem;
    color: ${({ theme }) => theme.colors.neutral[8]};
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding: 7.2rem 0;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        width: 32rem;
        position: absolute;
        right: 0;
        top: -13rem;
        padding: 2.4rem;
        z-index: 100;
    }
`;

const SecondCta = styled(Cta)`
    background: ${({ theme }) => theme.colors.primary[5]};
    position: unset;
    margin: auto;
    margin-left: -1.6rem;

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        width: 100%;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin: auto;
    }
`;

const StyledContentWrapper = styled(ContentWrapper)`
    height: 100vh;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        height: calc(80vh - var(--nav-height));
    }
    display: flex;
    flex-direction: column;
`;

const MainSection = styled.section`
    color: white;
`;

const VideoOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 66, 37, 0.5);
    /* opacity: 0.3; */
`;

const StyledVideo = styled.video`
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;

const VideoWrapper = styled(Appear)`
    width: 100vw;
    height: calc(100vh + var(--nav-height));
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        height: 80rem;
        max-height: 90vh;
    }

    position: absolute;
    left: 0;
    top: calc(var(--nav-height) * -1);
`;

// const ScrollIcon = styled(Appear)`
//     flex-shrink: 0;
//     margin: 0 auto;
//     color: white;
//     line-height: 1;
//     box-sizing: border-box;
//     width: 40px;
//     height: 60px;
//     box-shadow: inset 0 0 0 2px white;
//     border-radius: 50px;
//     margin-bottom: 2.4rem;
//     cursor: pointer;
//     transition: all 0.2s ease-in-out 0s;

//     @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
//         display: none;
//     }
//     transform: translateY(0);

//     ::after {
//         content: '';
//         width: 8px;
//         height: 12px;
//         background: white;
//         margin-left: 16px;
//         top: 16px;
//         border-radius: 4px;
//         animation-duration: 1.5s;
//         animation-iteration-count: infinite;
//         animation-name: scroll;
//         position: absolute;
//         @keyframes scroll {
//             0% {
//                 transform: translateY(0);
//             }
//             50% {
//                 transform: translateY(4px);
//             }
//             100% {
//                 transform: translateY(0);
//             }
//         }
//     }
// `;
