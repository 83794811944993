import React, { ComponentType, SVGAttributes } from 'react';

const Terms: ComponentType<SVGAttributes<SVGElement>> = props => {
    return (
        <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            width="634.3874"
            viewBox="0 0 634.3874 584.3497"
            {...props}
        >
            <path
                d="M291.2942 321.5024c-.1133 0-.1846-.0029-.2095-.0044l-13.5645.0015v-2h13.6135c.3152.0112 6.7459.1929 12.6709-6.9966 8.8296-10.7148 16.0806-37.2397.4814-102.7558-20.975-88.0957-3.481-165.9234 5.014-195.2954A9.7524 9.7524 0 00299.917 2H97.3414a9.7962 9.7962 0 00-7.8997 4.021c-3.5986 4.9502-8.537 14.46-11.6274 31.6553l-1.9683-.3535c3.1573-17.5689 8.2564-27.3584 11.978-32.4776A11.801 11.801 0 0197.3415 0h202.5754a11.7528 11.7528 0 0111.3045 15.0073c-8.4512 29.2197-25.8547 106.6436-4.9895 194.2769 15.8183 66.4375 8.2139 93.537-.9507 104.5718-6.045 7.2778-12.7053 7.6465-13.987 7.6465z"
                fill="#e6e6e6"
            />
            <path
                d="M254.7942 358.0024c-.1133 0-.1846-.0029-.2095-.0044l-13.5645.0015v-2h13.6135c.3169.0098 6.7459.1929 12.6709-6.9966 8.8296-10.7148 16.0806-37.2397.4814-102.7558-20.975-88.0957-3.481-165.9234 5.014-195.2954A9.7524 9.7524 0 00263.417 38.5H60.8414a9.7962 9.7962 0 00-7.8997 4.021c-3.5986 4.9502-8.537 14.46-11.6273 31.6553l-1.9685-.3535c3.1574-17.5689 8.2565-27.3584 11.9782-32.4776A11.801 11.801 0 0160.8415 36.5H263.417a11.7528 11.7528 0 0111.3045 15.0073c-8.4512 29.2197-25.8547 106.6436-4.9895 194.2769 15.8183 66.4375 8.2139 93.537-.9507 104.5718-6.045 7.2778-12.7053 7.6465-13.987 7.6465z"
                fill="#ccc"
            />
            <path
                d="M218.2942 394.5024c-.1133 0-.1846-.0029-.2095-.0044l-199.4532.0015a11.7501 11.7501 0 01-10.9283-16.102c7.2368-18.0806 16.9808-61.459.9315-148.8394C-10.3167 126.378 6.65 89.0884 14.824 77.8452A11.8013 11.8013 0 0124.3416 73H226.917a11.7528 11.7528 0 0111.3044 15.0073c-8.4512 29.2197-25.8547 106.6436-4.9895 194.2769 15.8184 66.4375 8.2141 93.537-.9507 104.5718-6.045 7.2778-12.7053 7.6464-13.987 7.6464zM24.3416 75a9.7964 9.7964 0 00-7.8999 4.021c-8.0225 11.0347-24.6515 47.7573-5.84 150.1758 15.332 83.4736 7.896 127.6137-1.0418 149.9438a9.5872 9.5872 0 00.977 9.0645 9.763 9.763 0 008.0946 4.2944h199.5022c.315.0152 6.7459.1934 12.6709-6.9966 8.8296-10.7148 16.0806-37.2397.4814-102.7558-20.975-88.0957-3.481-165.9234 5.014-195.2954A9.7524 9.7524 0 00226.917 75z"
                fill="#3f3d56"
            />
            <path d="M192.461 163h-159a8 8 0 110-16h159a8 8 0 010 16z" fill="#f8bc93" />
            <path
                d="M192.461 197h-159a8 8 0 110-16h159a8 8 0 010 16zM192.461 231h-159a8 8 0 110-16h159a8 8 0 010 16zM204.461 265h-159a8 8 0 110-16h159a8 8 0 010 16zM204.461 299h-159a8 8 0 110-16h159a8 8 0 010 16z"
                fill="#ccc"
            />
            <path d="M108.461 129h-75a8 8 0 110-16h75a8 8 0 010 16z" fill="#f8bc93" />
            <path
                d="M98.7767 353.8217c1.614-6.835 9.1979-9.0754 15.3983-8.8248a65.7283 65.7283 0 0111.6109 1.8372c3.984.8914 7.9294 1.9468 11.8549 3.0662 7.1985 2.0527 14.3227 4.5356 21.6398 6.1407 6.0914 1.3362 12.7111 2.1198 18.7859.2751 5.8394-1.7732 10.7933-6.1262 12.382-12.1446 1.4973-5.6716-.5583-11.8728-6.054-14.4646-5.0118-2.3635-11.3425-1.1616-13.8266 4.1836-2.8007 6.0266-.0023 12.5992 4.4325 16.9603a49.6677 49.6677 0 008.2378 6.257 30.6763 30.6763 0 009.8637 4.418c5.785 1.2356 12.6763-.5852 15.6596-6.112a10.3293 10.3293 0 001.1718-4.037c.1766-1.9218-2.8245-1.9098-3 0-.5057 5.5034-6.9507 8.0808-11.7703 7.4771a23.9239 23.9239 0 01-9.1254-3.5417 53.4723 53.4723 0 01-7.5582-5.3329c-4.0878-3.5375-7.9197-9.359-5.1742-14.8793 2.2355-4.495 8.0632-4.4502 11.6077-1.5513 4.2925 3.5107 3.5271 9.8827.696 14.0517-3.1349 4.6163-8.8168 6.5514-14.174 6.7602-5.821.2267-11.5934-1.109-17.1567-2.6889-6.9126-1.963-13.7563-4.1599-20.7021-6.0079-3.9433-1.0492-7.917-1.996-11.9313-2.7327a39.377 39.377 0 00-11.5695-.801c-6.26.723-12.65 4.3669-14.1914 10.8941-.4434 1.8775 2.4486 2.6787 2.8928.7976z"
                fill="#3f3d56"
            />
            <path
                d="M405.2763 523.4402a458.031 458.031 0 004.318 58.8546c.0946.685.1976 1.37.2926 2.055l184.4023-1.0304c.5415-.6803 1.0744-1.3687 1.5907-2.0654 1.7216-2.3056 3.3352-4.6768 4.8164-7.1297 7.1195-11.8172 10.3789-25.6528 7.1127-35.1986l-.0585-.1483a16.7502 16.7502 0 00-3.2693-5.6805c-7.4469-8.3826-22.5038-6.9853-36.1676-.2191 12.2556-13.4151 22.0142-30.343 22.7557-45.453.727-14.7381-6.9795-25.2667-15.3382-34.214-.2743-.2959-.5485-.5834-.8227-.871-.1331-.1479-.2742-.2875-.407-.4354-6.5215-6.8186-14.1244-13.5403-25.1293-12.7272-12.0694.8933-25.4509 11.0028-34.8334 22.7831-9.3825 11.772-15.6174 25.1453-21.9372 38.1969-6.3279 13.0433-13.1741 26.42-23.3284 37.5686 13.7533-17.396 24.842-37.1889 28.9382-56.4389s.4912-37.7384-11.7662-46.8044a28.669 28.669 0 00-12.5576-5.067c-.5288-.0879-1.066-.1593-1.6115-.2223-11.3469-1.291-24.8948 2.6334-36.4216 12.014-12.6942 10.3288-21.4952 25.6407-25.8742 40.1763-4.3786 14.5356-4.8452 28.5291-4.7039 42.0567z"
                fill="#f2f2f2"
            />
            <path
                d="M525.923 583.7014l3.5262-.0197q1.6794-1.05 3.3747-2.0754c.6996-.4416 1.4074-.8668 2.1156-1.3002q17.5442-10.715 35.308-20.556 17.7475-9.849 35.6735-18.7903a1.4778 1.4778 0 00.8495-1.6897.426.426 0 00-.0587-.1235c-.1667-.313-.5393-.443-1.083-.1757-1.4659.735-2.9402 1.47-4.4059 2.2216q-17.975 9.0945-35.7956 19.0967-17.8084 9.998-35.4138 20.8786c-.2468.15-.494.3083-.7407.4583-1.1193.6918-2.2305 1.3835-3.3497 2.0753zM480.4581 583.9554l2.1805-.0122c.4917-.6882.975-1.3764 1.4668-2.0647q17.3317-24.304 34.656-48.6082 28.3707-39.7896 56.7244-79.5792a1.4044 1.4044 0 00.2453-.4804c.1617-.5873-.2698-.9317-.8227-.8708a1.6564 1.6564 0 00-1.152.7332q-15.9062 22.314-31.8043 44.6115-28.223 39.5906-56.4379 79.1728c-1.1964 1.675-2.3928 3.3583-3.5892 5.0333-.4918.6883-.9836 1.3765-1.4669 2.0647zM431.882 571.2931c.1112 3.6747.3544 7.2908.672 10.8735q.0923 1.0277.185 2.0554l2.2877-.0127c-.07-.6851-.14-1.3702-.2015-2.0554-.4834-5.1758-.8264-10.41-.873-15.7865a220.2299 220.2299 0 014.3356-44.3918 276.3973 276.3973 0 0113.4278-46.1193 316.7237 316.7237 0 0121.97-45.6302 1.2187 1.2187 0 00.2019-.8105c-.0944-.652-.9625-.8454-1.6115-.2223a1.8938 1.8938 0 00-.328.4148q-1.583 2.722-3.1082 5.46a316.5046 316.5046 0 00-21.0278 45.7902 275.09 275.09 0 00-12.5184 46.1886 217.271 217.271 0 00-3.4117 44.2462z"
                fill="#fff"
            />
            <path
                d="M209.0047 354.5325a2.4999 2.4999 0 011.3621-4.4096 41.289 41.289 0 0116.1927 1.6597l53.3154 16.4656a5.5 5.5 0 01-3.246 10.5102l-53.3153-16.4656a41.2921 41.2921 0 01-14.3089-7.7603z"
                fill="#3f3d56"
            />
            <path
                d="M427.5517 422.932a10.7427 10.7427 0 01-4.868-15.7368l-9.1385-47.7916 23.2914-1.8159 1.406 46.747a10.801 10.801 0 01-10.6909 18.5974z"
                fill="#ffb8b8"
            />
            <path
                d="M412.1078 398.2667l-23.723-110.4824a17.4997 17.4997 0 1134.2198-7.3467l23.7232 110.4814a4.5056 4.5056 0 01-3.4549 5.3448l-25.4206 5.458a4.4984 4.4984 0 01-5.3445-3.455z"
                fill="#f8bc93"
            />
            <path fill="#ffb8b8" d="M373.17 572.064h-12.259l-5.833-47.288 18.095.001-.003 47.287z" />
            <path d="M352.1535 568.5607h23.6439v14.8869h-38.5307a14.8869 14.8869 0 0114.8868-14.887z" fill="#2f2e41" />
            <path fill="#ffb8b8" d="M414.17 571.064h-12.259l-5.833-47.288 18.095.001-.003 47.287z" />
            <path
                d="M393.1535 567.5607h23.6439v14.8869h-38.5307a14.8869 14.8869 0 0114.8868-14.887zM358.4816 563.2433a4.4692 4.4692 0 01-1.6162-3.0625l-14.2527-156.7793a4.5008 4.5008 0 014.313-4.9043l71.4797-2.6807a4.5015 4.5015 0 014.6658 4.6621l-5.7138 156.1768a4.5084 4.5084 0 01-4.0896 4.3164l-14.4129 1.3105a4.4995 4.4995 0 01-4.8972-4.1826l-6.6145-99.2168a1.436 1.436 0 00-1.4959-1.3994h-.006a1.4366 1.4366 0 00-1.4925 1.4111l-5.9192 99.8858a4.4791 4.4791 0 01-4.0442 4.211l-12.5908 1.2597a4.47 4.47 0 01-3.313-1.0078z"
                fill="#2f2e41"
            />
            <circle cx="385.9769" cy="218.9625" r="24.561" fill="#ffb8b8" />
            <path
                d="M338.6788 404.463a4.4618 4.4618 0 01-1.0527-3.6787c4.5344-27.5615 1.9788-68.0742-.9639-97.207a44.2009 44.2009 0 0118.2412-40.3018l12.827-10.9941a4.5697 4.5697 0 013.0573-1.082l31.866.9101a4.4832 4.4832 0 013.0535 1.3174l4.924 10.9043.0508.0371a44.0702 44.0702 0 0117.915 37.6905l-4.6904 96.9365a4.5241 4.5241 0 01-4.347 4.2803l-77.3473 2.7177c-.05.001-.0996.002-.1494.002a4.4893 4.4893 0 01-3.384-1.5323z"
                fill="#f8bc93"
            />
            <path
                d="M245.9029 364.012a10.7427 10.7427 0 0112.366-10.8825l39.865-27.8985 11.1897 20.508-42.0768 20.4156a10.801 10.801 0 01-21.344-2.1427z"
                fill="#ffb8b8"
            />
            <path
                d="M279.4515 368.8614a4.4733 4.4733 0 01-2.9453-1.7851l-15.3845-20.96a4.5064 4.5064 0 01.9649-6.291l91.0947-66.8633a17.5003 17.5003 0 0120.71 28.2158l-91.0948 66.8633a4.4689 4.4689 0 01-2.6528.873 4.6023 4.6023 0 01-.6922-.0527z"
                fill="#f8bc93"
            />
            <path
                d="M387.2341 240.8269c3.2572-3.9937 2.9006-10.0769.2864-14.518s-7.0519-7.5-11.6181-9.889-9.4171-4.2679-13.7143-7.1126-4.1099-14.8874-5.254-19.9123l2.729.909c-1.049-1.3418-1.3854-5.3067-2.4342-6.6486 3.0836.3047 5.4548 3.2325 8.5385 3.5372l-1.7795-4.7764c10.5744-.652 17.1488 6.696 27.7232 6.0442 5.2392-.323 10.929-.5274 15.236 2.473 3.7428 2.6075 5.6375 7.1204 6.8806 11.5091 2.6692 9.4232 3.084 19.9788-1.5261 28.6198s-15.2367 14.4638-24.5203 11.3436z"
                fill="#2f2e41"
            />
            <path d="M252.3874 584h381a1 1 0 000-2h-381a1 1 0 000 2z" fill="#3f3d56" />
        </svg>
    );
};

export default Terms;
