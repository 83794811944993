import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { useStepFormDispatch } from '../context/stepform';
import Appear from './Appear';
import Flex from './atoms/Flex';
import { StepContext, StepOne, Steps, StepState, Wrapper } from './StepForm';
import { TitleInner } from './atoms/Title';
import { getFontCss } from '../constants/theme';

export const detailsValidation = Yup.object().shape({
    name: Yup.string().required('Vul een naam in'),
    phone: Yup.number().required('Vul een telefoonnummer in'),
    email: Yup.string()
        .email('Vul een geldig email in')
        .required('Vul een email in'),
});

const StepInline = ({ className }: { className?: string }) => {
    const [state, setState] = useState<StepState>({ active: 0, completed: [] });
    const { onOpen } = useStepFormDispatch();

    return (
        <Container
            className={className}
            from={{ opacity: 0, transform: 'scale(0.7)' }}
            delay={500}
            config="slow"
            to={{ opacity: 1, transform: 'scale(1)' }}
            disableInView
        >
            <StepContext stepState={state} dispatch={setState}>
                <Flex flexDirection="column">
                    <Steps inline />

                    <Flex pt={5} flexDirection="column" flex={[null, null, 1]}>
                        <Formik
                            validateOnBlur={false}
                            validationSchema={detailsValidation}
                            initialValues={{ name: '', phone: '', email: '' }}
                            onSubmit={(vals, { resetForm }) => {
                                if (!('name' in vals)) {
                                    return;
                                }
                                onOpen(vals);
                                resetForm();
                            }}
                        >
                            {({ submitForm }) => (
                                <Form>
                                    <StepOne onSubmit={submitForm} />
                                </Form>
                            )}
                        </Formik>
                    </Flex>
                </Flex>
            </StepContext>
        </Container>
    );
};

export default StepInline;

const Container = styled(Appear)`
    ${TitleInner} {
        ${({ theme }) => getFontCss(theme.fontSizes.title.regular)}
    }
    background: ${({ theme }) => theme.colors.primary[5]};
    z-index: 5;
    padding: 2.4rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        position: absolute;
        width: 20rem;
        right: 2rem;
        box-shadow: 0 10px 19px 0 rgba(0, 0, 0, 0.3);
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        top: 10vh;
    }

    input,
    textarea {
        ::placeholder {
            color: white;
            opacity: 0.6;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        padding: 3.2rem;
        width: 22rem;
    }
    color: white;
    ${Wrapper} {
        padding: 0;
        margin: 0;
    }
`;
